// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../api/Api.res.js";
import * as Url from "../utils/Url.res.js";
import * as $$Promise from "../bindings/Promise.res.js";
import * as SentryLogger from "../loggers/SentryLogger.res.js";
import * as Routes_Project from "../routes/common/Routes_Project.res.js";

function cloneProject(id, category) {
  $$Promise.wait(Api.cloneProject(id), (function (x) {
          if (x.TAG !== "Ok") {
            return SentryLogger.error1({
                        rootModule: "ProjectService",
                        subModulePath: /* [] */0,
                        value: "cloneProject",
                        fullPath: "ProjectService.cloneProject"
                      }, "UserProject::Clone::Error", [
                        "Error",
                        x._0
                      ]);
          }
          var tmp;
          switch (category.TAG) {
            case "Colocation" :
                tmp = Routes_Project.Colocation.$$new;
                break;
            case "Cloud" :
                tmp = category._0 === "IaasServer" ? Routes_Project.IaasServer.$$new : Routes_Project.IaasStorage.$$new;
                break;
            case "Internet" :
                tmp = Routes_Project.Internet.$$new;
                break;
            case "Network" :
                switch (category._0) {
                  case "SdWan" :
                      tmp = Routes_Project.Network.SdWan.$$new;
                      break;
                  case "PrivateLine" :
                      tmp = Routes_Project.Network.PrivateLine.$$new;
                      break;
                  case "Mpls" :
                      tmp = Routes_Project.Network.Mpls.$$new;
                      break;
                  
                }
                break;
            case "BareMetal" :
                tmp = Routes_Project.BareMetal.$$new;
                break;
            
          }
          Url.visit(tmp);
        }));
}

export {
  cloneProject ,
}
/* Api Not a pure module */
