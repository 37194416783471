// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConciergeTopNavbarScss from "./ConciergeTopNavbar.scss";

var css = ConciergeTopNavbarScss;

var categories = [{
    id: "Projects",
    slug: Routes_Project.Dashboard.conciergeIndex
  }];

function ConciergeTopNavbar(props) {
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.map(categories, (function (category) {
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: category.slug,
                                              className: css.linkItem,
                                              children: "Concierge Projects"
                                            }),
                                        className: Cx.cx([
                                              css.linkContainer,
                                              category.id === selectedCategory ? css.underlinedLink : ""
                                            ])
                                      }, category.slug);
                          })),
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = ConciergeTopNavbar;

export {
  css ,
  categories ,
  make ,
}
/* css Not a pure module */
