// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconCanceled(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Canceled";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M2 3.5L3.5 2l4.25 4.25L12 2l1.5 1.5-4.25 4.25 4.25 4.247L12 13.5 7.75 9.25 3.5 13.5 2 11.997 6.25 7.75z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconCanceled;

export {
  make ,
}
/* Icon Not a pure module */
