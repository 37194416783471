// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconClock(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Clock";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M0 0h20v20H0z"
                          }),
                      JsxRuntime.jsx("circle", {
                            cx: "10",
                            cy: "10",
                            r: "6.3",
                            stroke: Icon.mapColor(color),
                            strokeWidth: "1.5"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M10 6.5V10l2 2",
                            stroke: Icon.mapColor(color),
                            strokeLinecap: "square",
                            strokeWidth: "1.5"
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd",
                    transform: "translate(-3 -3)"
                  })
            });
}

var make = IconClock;

export {
  make ,
}
/* Icon Not a pure module */
