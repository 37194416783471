// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconNoBid(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Canceled";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("circle", {
                            cx: "8",
                            cy: "8",
                            r: "6.25",
                            stroke: Icon.mapColor(color),
                            strokeWidth: "1.5"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M12 3l1 1-9 9-1-1z",
                            fill: Icon.mapColor(color)
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd"
                  })
            });
}

var make = IconNoBid;

export {
  make ,
}
/* Icon Not a pure module */
