// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../styleguide/icons/IconMenu.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as IconClock from "../../../../../styleguide/icons/IconClock.res.js";
import * as IconClose from "../../../../../styleguide/icons/IconClose.res.js";
import * as IconNoBid from "../../../../../styleguide/icons/IconNoBid.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconCanceled from "../../../../../styleguide/icons/IconCanceled.res.js";
import * as IconFinalized from "../../../../../styleguide/icons/IconFinalized.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as ProjectProposal from "../../../../../models/ProjectProposal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectProposalStatusDropdownScss from "./ProjectProposalStatusDropdown.scss";

var css = ProjectProposalStatusDropdownScss;

function getIcon(status, color, size) {
  if (status === "Canceled" || status === "Expired") {
    return JsxRuntime.jsx(IconCanceled.make, {
                size: size,
                color: color
              });
  } else if (status === "NoBid") {
    return JsxRuntime.jsx(IconNoBid.make, {
                size: size,
                color: color
              });
  } else if (status === "Preparing") {
    return JsxRuntime.jsx(IconMenu.make, {
                size: size,
                color: color
              });
  } else if (status === "Finalized") {
    return JsxRuntime.jsx(IconFinalized.make, {
                size: size,
                color: color
              });
  } else {
    return JsxRuntime.jsx(IconClock.make, {
                size: size,
                color: color
              });
  }
}

function getClassName(status) {
  return Cx.cx([
              css.proposalDropdownTrigger,
              status === "Expired" ? css.expired : (
                  status === "Canceled" ? css.cancelled : (
                      status === "NoBid" ? css.nobid : (
                          status === "Preparing" ? css.preparing : (
                              status === "Finalized" ? css.finalized : css.inProgress
                            )
                        )
                    )
                )
            ]);
}

function ProjectProposalStatusDropdown(props) {
  var __position = props.position;
  var setBidModalVisibility = props.setBidModalVisibility;
  var updateProposalStatus = props.updateProposalStatus;
  var userViewContext = props.userViewContext;
  var projectId = props.projectId;
  var proposal = props.proposal;
  var position = __position !== undefined ? __position : ({
        TAG: "Below",
        _0: "LeftEdge"
      });
  var match = React.useState(function () {
        return "Hidden";
      });
  var setFinalizeModalVisibility = match[1];
  var match$1 = React.useState(function () {
        return "Hidden";
      });
  var setStatusModalVisibility = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var setStatusMessage = match$2[1];
  var statusMessage = match$2[0];
  var tmp;
  switch (userViewContext) {
    case "Admin" :
        tmp = "";
        break;
    case "Personal" :
    case "Visitor" :
        tmp = css.preparingCursor;
        break;
    
  }
  var match$3 = proposal.status;
  var tmp$1;
  if (match$3 === "Finalized" || match$3 === "NoBid" || match$3 === "Canceled" || match$3 === "Expired") {
    tmp$1 = false;
  } else if (match$3 === "Preparing") {
    switch (userViewContext) {
      case "Admin" :
          tmp$1 = true;
          break;
      case "Personal" :
      case "Visitor" :
          tmp$1 = false;
          break;
      
    }
  } else {
    tmp$1 = true;
  }
  var match$4 = proposal.status;
  var tmp$2;
  if (match$4 === "Finalized" || match$4 === "NoBid" || match$4 === "Canceled" || match$4 === "Expired") {
    tmp$2 = null;
  } else if (match$4 === "Preparing") {
    switch (userViewContext) {
      case "Admin" :
          tmp$2 = JsxRuntime.jsxs(Dropdown.Body.make, {
                position: position,
                className: css.dropdownBody,
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: "Send Proposal",
                              className: css.label,
                              onClick: (function (param) {
                                  Url.visit(Routes_Project.Proposal.$$new(projectId));
                                })
                            }),
                        className: css.dropdownBodyRow
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: "No Bid",
                              className: css.label,
                              onClick: (function (param) {
                                  setBidModalVisibility("Shown");
                                })
                            }),
                        className: css.dropdownBodyRow
                      })
                ]
              });
          break;
      case "Personal" :
      case "Visitor" :
          tmp$2 = null;
          break;
      
    }
  } else {
    tmp$2 = JsxRuntime.jsx(Dropdown.Body.make, {
          position: position,
          className: css.dropdownBody,
          children: Belt_Array.mapWithIndex(Belt_Array.keep(ProjectProposal.Status.statusList(userViewContext), (function (item) {
                      return item !== proposal.status;
                    })), (function (idx, item) {
                  return JsxRuntime.jsxs("div", {
                              children: [
                                getIcon(item, "DarkGray", "XS"),
                                JsxRuntime.jsx("div", {
                                      children: ProjectProposal.Status.toString(item),
                                      className: css.label,
                                      onClick: (function (param) {
                                          if (item === "Canceled") {
                                            return setStatusModalVisibility(function (param) {
                                                        return "Shown";
                                                      });
                                          } else if (item === "Preparing") {
                                            return ;
                                          } else if (item === "Finalized") {
                                            return setFinalizeModalVisibility(function (param) {
                                                        return "Shown";
                                                      });
                                          } else {
                                            return updateProposalStatus(projectId, {
                                                        id: proposal.id,
                                                        description: proposal.description,
                                                        providerLogo: proposal.providerLogo,
                                                        providerName: proposal.providerName,
                                                        updatedAt: proposal.updatedAt,
                                                        lastViewedAt: proposal.lastViewedAt,
                                                        sentAt: proposal.sentAt,
                                                        expiresAt: proposal.expiresAt,
                                                        status: item,
                                                        almostExpired: proposal.almostExpired,
                                                        statusMessage: proposal.statusMessage,
                                                        tourDate: proposal.tourDate,
                                                        tourRequested: proposal.tourRequested,
                                                        projectCategory: proposal.projectCategory,
                                                        projectSubcategory: proposal.projectSubcategory,
                                                        additionalDocuments: proposal.additionalDocuments
                                                      }, undefined, undefined);
                                          }
                                        })
                                    })
                              ],
                              className: css.dropdownBodyRow
                            }, String(idx));
                }))
        });
  }
  var tmp$3;
  if (match$1[0] === "Shown") {
    var tmp$4;
    switch (userViewContext) {
      case "Personal" :
          tmp$4 = proposal.providerName;
          break;
      case "Admin" :
          tmp$4 = "the client";
          break;
      case "Visitor" :
          tmp$4 = "";
          break;
      
    }
    var tmp$5;
    switch (userViewContext) {
      case "Personal" :
          tmp$5 = "Message to " + proposal.providerName;
          break;
      case "Admin" :
          tmp$5 = "Message to the client";
          break;
      case "Visitor" :
          tmp$5 = "";
          break;
      
    }
    tmp$3 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(Control.make, {
                                className: css.closeOverlayButton,
                                onClick: (function (param) {
                                    setStatusModalVisibility(function (param) {
                                          return "Hidden";
                                        });
                                  }),
                                children: JsxRuntime.jsx(IconClose.make, {
                                      title: "Close",
                                      size: "MD",
                                      color: "Gray"
                                    })
                              }),
                          JsxRuntime.jsx("h2", {
                                children: "Cancel proposal"
                              }),
                          JsxRuntime.jsxs("p", {
                                children: [
                                  "Write a message to let ",
                                  tmp$4,
                                  " know the reason you are canceling the proposal"
                                ],
                                className: css.description
                              }),
                          JsxRuntime.jsx("label", {
                                children: tmp$5,
                                className: css.label
                              }),
                          JsxRuntime.jsx(Textarea.make, {
                                id: "status--message",
                                value: statusMessage,
                                rows: 5,
                                placeholder: "Describe the reason",
                                className: css.message,
                                onChange: (function ($$event) {
                                    setStatusMessage($$event.target.value);
                                  })
                              })
                        ],
                        className: css.statusMessageModalContent
                      }),
                  JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(Button.make, {
                                size: "SM",
                                color: "Teal",
                                onClick: (function (param) {
                                    updateProposalStatus(projectId, {
                                          id: proposal.id,
                                          description: proposal.description,
                                          providerLogo: proposal.providerLogo,
                                          providerName: proposal.providerName,
                                          updatedAt: proposal.updatedAt,
                                          lastViewedAt: proposal.lastViewedAt,
                                          sentAt: proposal.sentAt,
                                          expiresAt: proposal.expiresAt,
                                          status: "Canceled",
                                          almostExpired: proposal.almostExpired,
                                          statusMessage: proposal.statusMessage,
                                          tourDate: proposal.tourDate,
                                          tourRequested: proposal.tourRequested,
                                          projectCategory: proposal.projectCategory,
                                          projectSubcategory: proposal.projectSubcategory,
                                          additionalDocuments: proposal.additionalDocuments
                                        }, statusMessage, undefined);
                                    setStatusModalVisibility(function (param) {
                                          return "Hidden";
                                        });
                                  }),
                                children: "Submit"
                              }),
                          JsxRuntime.jsx(Button.make, {
                                size: "SM",
                                color: "Gray",
                                onClick: (function (param) {
                                    setStatusModalVisibility(function (param) {
                                          return "Hidden";
                                        });
                                  }),
                                children: "Cancel"
                              })
                        ],
                        className: css.statusMessageButtons
                      })
                ],
                className: css.statusMessageModalContainer
              }),
          className: css.modalMessageOverlay
        });
  } else {
    tmp$3 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(Dropdown.make, {
                      className: css.proposalStatus,
                      children: [
                        JsxRuntime.jsx(Dropdown.Trigger.make, {
                              className: Cx.cx([
                                    getClassName(proposal.status),
                                    tmp
                                  ]),
                              iconSize: props.iconSize,
                              iconColor: "White",
                              isBodyExists: tmp$1,
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      getIcon(proposal.status, "White", "SM"),
                                      ProjectProposal.Status.toString(proposal.status)
                                    ],
                                    className: css.pillTitle
                                  })
                            }),
                        tmp$2
                      ]
                    }),
                match[0] === "Shown" ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.proposalModalMessageText
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  setFinalizeModalVisibility(function (param) {
                                                        return "Hidden";
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  updateProposalStatus(projectId, {
                                                        id: proposal.id,
                                                        description: proposal.description,
                                                        providerLogo: proposal.providerLogo,
                                                        providerName: proposal.providerName,
                                                        updatedAt: proposal.updatedAt,
                                                        lastViewedAt: proposal.lastViewedAt,
                                                        sentAt: proposal.sentAt,
                                                        expiresAt: proposal.expiresAt,
                                                        status: "Finalized",
                                                        almostExpired: proposal.almostExpired,
                                                        statusMessage: proposal.statusMessage,
                                                        tourDate: proposal.tourDate,
                                                        tourRequested: proposal.tourRequested,
                                                        projectCategory: proposal.projectCategory,
                                                        projectSubcategory: proposal.projectSubcategory,
                                                        additionalDocuments: proposal.additionalDocuments
                                                      }, undefined, undefined);
                                                  setFinalizeModalVisibility(function (param) {
                                                        return "Hidden";
                                                      });
                                                }),
                                              children: "Yes, finalize it"
                                            })
                                      ],
                                      className: css.modalMessageButtons
                                    })
                              ],
                              className: css.proposalModalMessageContainer
                            }),
                        className: css.modalMessageOverlay
                      }) : null,
                tmp$3
              ],
              className: props.className
            });
}

var make = ProjectProposalStatusDropdown;

export {
  css ,
  getIcon ,
  getClassName ,
  make ,
}
/* css Not a pure module */
