// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconUserAvatar(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Avatar";
  var fill = color !== undefined ? Icon.mapColor(color) : "#D7DEE2";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "40",
              className: props.className,
              children: [
                JsxRuntime.jsx("defs", {
                      children: JsxRuntime.jsx("circle", {
                            id: "a",
                            cx: "20",
                            cy: "20",
                            r: "20"
                          })
                    }),
                JsxRuntime.jsxs("g", {
                      children: [
                        JsxRuntime.jsx("mask", {
                              children: JsxRuntime.jsx("use", {
                                    xlinkHref: "#a"
                                  }),
                              id: "b",
                              fill: "#fff"
                            }),
                        JsxRuntime.jsx("use", {
                              fill: fill,
                              fillRule: "nonzero",
                              xlinkHref: "#a"
                            }),
                        JsxRuntime.jsx("circle", {
                              cx: "20",
                              cy: "17.6",
                              fill: "#F3F7F9",
                              fillRule: "nonzero",
                              mask: "url(#b)",
                              r: "8"
                            }),
                        JsxRuntime.jsx("circle", {
                              cx: "20",
                              cy: "39.2",
                              fill: "#F3F7F9",
                              fillRule: "nonzero",
                              mask: "url(#b)",
                              r: "12"
                            })
                      ],
                      fill: "none",
                      fillRule: "evenodd"
                    })
              ]
            });
}

var make = IconUserAvatar;

export {
  make ,
}
/* Icon Not a pure module */
